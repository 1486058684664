import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  const backgroundImage = '/404-background.png'; // Path to your image in the public folder

  return (
    <Box style={{ backgroundImage: `url(${backgroundImage})`, ...styles.background }}>
      <Container component="main" maxWidth="md" sx={styles.container}>
        <Box sx={styles.textWrapper}>
          <Typography variant="h4" component="h4" gutterBottom>
            404
          </Typography>
          <Typography variant="h4" gutterBottom>
            Oops! Page not found.
          </Typography>
          <Button variant="contained" color="primary" component={Link} to="/">
            Go Home
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

// Styles
const styles = {
  background: {
    width: '100%',
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    textAlign: 'center',
  },
  textWrapper: {
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    padding: 3,
    borderRadius: 2,
    boxShadow: 1,
  },
};

export default NotFoundPage;
