import React, { useState } from 'react';
import { Button, TextField, Snackbar, Alert } from '@mui/material';
import ReactGA from 'react-ga4';



const SignupComponent = () => {
  const [email, setEmail] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Or 'error'
  const [submissionStatus, setSubmissionStatus] = useState('success');
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      const apiKey = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiYmQ3M2NlOWFlODYzZjQwYmQ4MmEzMTU3NjZmMzE3ZGI0MDJjYzljMDQwYzAzNDE5NTU5OWViMGE0MGM3NzAzYWY2NWFiYzZjNTQ3YjUyNTUiLCJpYXQiOjE3MTEyMDkyMjYuNTUyMiwibmJmIjoxNzExMjA5MjI2LjU1MjIwMiwiZXhwIjo0ODY2ODgyODI2LjU0ODg5Mywic3ViIjoiODkyMTE4Iiwic2NvcGVzIjpbXX0.HLGsBdJZ00n1nMNdmB_R1lmRjIW1cVbwkAgCbpj4ZM0SrwnIwADnOk8gpRK0tUyrB8-jI75gCvbco0wcbDKK5M0wpHdPNlIfB9MiGhJ4EDnIb8eZgzvNmv8SVzpywxh7zZ66XqLvdm7REA6VrxHM9qgXdQSxc2BarjwBiFTkwPBpvEAQOqlxOInpNe19K-YJbzhZhyEm8ZQmndYE6qNctjf3pcgp2smrqjvuK9aZb6nzIMm1OLouonmZ2b7OCzyjLZfGn0c50qyYdGIsqUzNmONh1ETmTllvcgoMpBr-9fFaTW5HjaoIa7pbsCT1c0buQf81k6RfMbFxd-iBdf-ZNyo7CX1zboKrHVEXhElCzDdc80ua8CP9VW4VwWeV80c3_jSB9DYnDFjXpcDFFv5mHG5pbY085rHS7QQEzpvm2sSnyTbAhkG24R-YKtHJo9J4x8LqDsv7xMKyvFGpjsDBKvqjYIpjp8PG-aUp5SxiduAJR6iWZwg4PXT224SwL5SPbl9oT_Q68CG5j3DWvEgxhrhuifMvzVWJSFrEvce6bpsXdNYW105MlBVJP-uy863yWSXpnT5hYUyt1j5mJ_3FO1dt-3FV9gTusaRD5rWldI71Aw6yEKPgYJYmDK9bQNDW-HOyBEimHzsYJ8e50yR3fAFhOf3WxV0kweCjoQFNAh4';
      const groupId = '116601633377355730';
      const url = 'https://connect.mailerlite.com/api/subscribers'; 
    //   const url = `https://api.mailerlite.com/api/v2/groups/${groupId}/subscribers`;

  
    try {
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({ 
              email, 
              fields: { 
                group: '116601633377355730', // Replace with your actual group name
                timestamp: new Date().toISOString() 
            }
          }), 
          headers: { 
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
           }
        });
  
        if (response.ok) {
          setSubmissionStatus('success');
          setEmail(''); 

          ReactGA.event({
            category: 'signup',
            action: 'email_signup',
            label: 'beta'
            });
          
          setSnackbarSeverity('success');
          setOpenSnackbar(true);
        } else {
            // console.log(apiKey);
            console.log(groupId);
            setSnackbarSeverity('error');
          setSubmissionStatus('error');
        }
      } catch (error) {
        console.error('Error submitting to MailerLite:', error);
        setSubmissionStatus('error');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      }
    };

    return (
        <form onSubmit={handleSubmit}> 
          <TextField 
            id="email" // Adding an ID is good for labels
            label="Enter your email"
            type="email" 
            name="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            sx={{
                input: {  // Target the inner input element
                  color: 'black',
                  fontSize: '18px',
                  textAlign: 'center'
                }
              }}
            fullWidth // To make it take up full width
            margin="normal" // Adding some spacing
            required 
          />
    
          <Button 
            type="submit"
            variant="contained" // Makes the button solid
            // color="orange"   // Customize the color if needed
            fullWidth
            sx={{ margin: '10px 0' }} // Additional spacing
          >
            Sign Up
          </Button>

          <Snackbar 
      open={openSnackbar} 
      autoHideDuration={3000} // Automatically hide after 3 seconds
      onClose={() => setOpenSnackbar(false)}
    >
      <Alert severity={snackbarSeverity} sx={{ width: '100%' }}>
        {submissionStatus === 'success' 
          ? 'Thanks for signing up!'
          : 'Oops! Something went wrong.'
        }
      </Alert>
    </Snackbar>
        </form>
      );
};

export default SignupComponent;


// return (
//     <form onSubmit={handleSubmit}> 
//       <TextField 
//         id="email" // Adding an ID is good for labels
//         label="Enter your email"
//         type="email" 
//         name="email" 
//         value={email} 
//         onChange={(e) => setEmail(e.target.value)} 
//         fullWidth // To make it take up full width
//         margin="normal" // Adding some spacing
//         required 
//       />

//       <Button 
//         type="submit"
//         variant="contained" // Makes the button solid
//         color="primary"   // Customize the color if needed
//         fullWidth
//         sx={{ margin: '10px 0' }} // Additional spacing
//       >
//         Sign Up
//       </Button>
//     </form>
//   );