// src/App.js
import React, { useEffect } from "react";
import { useLocation, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Layout from './components/Layout';
import ReactGA from 'react-ga4';

import LandingPage from './pages/LandingPage';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import About from './pages/About';
import NotFoundPage from './pages/NotFoundPage';

const TRACKING_ID = "G-J6DE7PQMJD"; 
ReactGA.initialize(TRACKING_ID, { debug: true });

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ page: location.pathname }); 
  }, [location]);
  return <div></div>; // An empty component
};

function App() {
  return (

    <ThemeProvider theme={theme}>
      <Router>
      <RouteChangeTracker />
      <Layout>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          {/* <Route path="/create-account" element={<SignupPage />} /> */}
          {/* <Route path="/login" element={<LoginPage />} /> */}
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        </Layout>
      </Router>
    </ThemeProvider>

  );
}

export default App;