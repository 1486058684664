import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// import Navbar from './Navbar';
// import Footer from './Footer';

const Layout = ({ children }) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {/* Header */}
        {/* <Navbar /> */}
  
        {/* Main Content */}
        <main style={{ flexGrow: 1 }}>
          {children}
        </main>
  
        {/* Footer */}
        <Box component="footer" sx={{ bgcolor: 'white', p: 3, mt: 'auto' }}>
          <Container maxWidth="sm">
            <Typography variant="body1" align="center" color="black">
              &copy; {new Date().getFullYear()} My Wellbeing by DataFenix. All rights reserved.
            </Typography>
          </Container>
        </Box>
      </Box>
    );
  };

export default Layout;