import React from 'react';
import { Box, Typography, Container, List, ListItem, ListItemText } from '@mui/material';
import HeroImage from '../images/liquid.jpg';
import SignupComponent from '../components/EmailSignUp';

const HeroContainer = ({ children }) => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Adjust to 100vh for full screen height on mobile
    backgroundImage: `url(${HeroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 2, // Reduced padding for mobile
  }}>
    <Container>
      {children}
    </Container>
  </Box>
);

const FeatureList = () => (
  <List sx={{
    mt: 2, // Added margin top for spacing on mobile
    bgcolor: 'background.paper',
    borderRadius: 2,
    overflow: 'hidden',
    boxShadow: 3,
    ml: { md: 4 }, // Margin left only on medium devices and up
  }}>
    {['Cycle-Aware Journaling: Your safe space to track symptoms, moods, and discover patterns.',
      'Personalized Insights: Our AI companion learns from your input, offering tailored insights and advice.',
      'Proactive Support: Get cycle-synced suggestions for fitness, nutrition, stress management, and more.',
      'Content Curation: Evidence-based articles and resources to deepen your cycle knowledge.'].map((text, index) => (
        <ListItem key={index} alignItems="flex-start">
          <ListItemText primary={text.split(': ')[0]} secondary={text.split(': ')[1]} />
        </ListItem>
    ))}
  </List>
);

const LandingPage = () => {
  return (
    <HeroContainer>
      <Box sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        mt: { xs: -6, sm: 0 }, // Adjust top margin on mobile for tighter spacing
      }}>
        {/* Left Side */}
        <Box sx={{
          mb: 3, // Adjust bottom margin for mobile
          flexGrow: 1,
          textAlign: { xs: 'center', sm: 'left' }, // Center text on mobile
        }}>
          <Typography variant="h1" gutterBottom sx={{ fontSize: { xs: '2.5rem', sm: '3rem', md: '4rem' } }}>
            Thea
          </Typography>
          <Typography variant="h2" sx={{ fontSize: { xs: '1.75rem', sm: '2.25rem', md: '3rem' }, fontWeight: 'fontWeightMedium' }}>
            Your AI powered expert in Women's Health
          </Typography>

          <Box mt={2} mb={2}> {/* Controlled spacing around the signup prompt */}
            <Typography variant="h6">Join the Beta Waitlist</Typography>
            <SignupComponent />
          </Box>
        </Box>

        {/* Right Side */}
        <FeatureList />
      </Box>
    </HeroContainer>
  );
};

export default LandingPage;
