import {createClient} from '@sanity/client';

export const client = createClient({
  projectId: 'n9kztttd',
  dataset: 'production',
  useCdn: true, // set to `false` to bypass the edge cache
  apiVersion: '2022-03-07', // use current date (YYYY-MM-DD) to target the latest API version
  // token: process.env.SANITY_SECRET_TOKEN // Only if you want to update content with the client
})

const ContentService = {
    fetchContentByTitle: async (title) => {
      const query = `*[_type == "terms" && title == $title][0]`;
      const params = { title };
      const content = await client.fetch(query, params);
      return content;
    }
  };
  
export default ContentService;