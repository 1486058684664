// src/pages/TermsOfService.js
import React, { useEffect, useState } from 'react';
import { Container, Typography } from '@mui/material';
import { PortableText } from '@portabletext/react';
import ContentService from '../services/ContentService'; // Adjust the path as necessary

function About() {
    const [content, setContent] = useState({ title: '', body: [], updatedAt: '' });

    useEffect(() => {
        ContentService.fetchContentByTitle("About")
            .then(data => {
                if (data) {
                    setContent({
                        title: data.title,
                        body: data.body,
                        updatedAt: data._updatedAt // Adjust this if your date field has a different name
                    });
                }
            });
    }, []);

    const formattedDate = new Date(content.updatedAt).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });

    return (
        <div>
            <Container>
                <Typography gutterBottom variant="h1" component="h1" style={{ marginTop: '20px' }}>
                    {content.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" style={{ marginBottom: '20px' }}>
                    {formattedDate}
                </Typography>
                <PortableText value={content.body} />
            </Container>
        </div>
    );
}

export default About;